
export default {
  data: () => ({
    page: 1,
    content: [],
    intervalID: null,
    communityID: 300,
  }),
  methods: {
    async addPage() {
      const res = await this.$getSectionContent(
        "questions",
        this.communityID,
        this.page
      );
      this.content = [...this.content, ...res];
      console.info(this.content);
      this.page = this.page + 1;
      if (res.length < 10) {
        clearInterval(this.intervalID);
      }
    },
    async getData() {},
  },
  created() {
    this.intervalID = setInterval(this.addPage, 3000);
  },
};
