
export default {
  ssr: false,
  fetchOnServer: false,
  layout: "landing",
  data() {
    return {
      data: [],
      categories: [],
      page: "",
      filter: {
        keyword: "",
        category_id: "",
        limit: 10,
        page: 1,
      },
      activeCategory: "",
      isLoading: false,
      isLastPage: false,
      isFiltering: "",
      noResult: false,
    };
  },

  async fetch() {
    const categories = await this.$axios.get(
      `${process.env.BASE_API}marketplace/categories`
    );
    this.categories = categories.data.body;
    this.fetchListing();
  },
  methods: {
    async fetchListing() {
      this.isLoading = true;
      try {
        const res = await this.$getListingContent(
          this.filter.keyword,
          this.filter.category_id,
          this.filter.limit,
          this.filter.page
        );
        if (this.filter.page !== 1 && !res.length) this.isLastPage = true;
        else this.isLastPage = false;
        this.data.push(...res);
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    async filterListing(id = "") {
      this.data = [];
      this.filter.category_id = id;
      if (id)
        this.activeCategory = this.categories.find(
          (category) => category.id === id
        ).label_id;
      else this.activeCategory = "";
      this.filter.page = 1;
      await this.fetchListing();
      if (!this.data.length) this.noResult = true;
      else this.noResult = false;
      this.isFiltering = false;
    },
    async fetchMoreData() {
      this.filter.page = this.filter.page + 1;
      await this.fetchListing();
    },
    clearSearch() {
      this.filter.keyword = "";
      this.data = [];
      this.fetchListing();
    },
  },
};
